import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import moment from "moment";

export interface RiderReportSummary {
  com: number;
  credit: number;
  net_revenue: number;
  revenue: number;
  "serviceFood/Mes/Free": string;
  tax_com: number;
}

@Module
export default class ReportModule extends VuexModule {
  originalOrderConfig = null;
  orderReportData = [];
  orderReportConfig = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    orderType: 1,
    orderStatus: 0,
  };

  originalPromotionConfig = null;
  promotionReportData = [];
  promotionReportConfig = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  originalRiderConfig = null;
  riderReportData = [];
  riderReportSummary = {} as RiderReportSummary;

  riderReportConfig = {
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    riderStatus: 1,
    riderName: "",
  };

  get orderReport(): Array<string> {
    return this.orderReportData;
  }

  get promotionReport(): Array<string> {
    return this.promotionReportData;
  }

  get riderReport(): Array<string> {
    return this.riderReportData;
  }

  get riderSummary(): RiderReportSummary {
    return this.riderReportSummary;
  }

  @Mutation
  [Mutations.SET_ORDER_REPORT](data) {
    this.orderReportData = data;
    this.originalOrderConfig = JSON.parse(
      JSON.stringify(this.orderReportConfig)
    );
  }

  @Mutation
  [Mutations.SET_PROMOTION_REPORT](data) {
    this.promotionReportData = data;
    this.originalPromotionConfig = JSON.parse(
      JSON.stringify(this.promotionReportConfig)
    );
  }

  @Mutation
  [Mutations.SET_ORIGINAL_ORDER_CONFIG](data) {
    this.originalOrderConfig = data;
  }

  @Mutation
  [Mutations.SET_ORIGINAL_PROMOTION_CONFIG](data) {
    this.originalPromotionConfig = data;
  }

  @Mutation
  [Mutations.SET_RIDER_REPORT](data) {
    this.riderReportData = data.data;
    this.riderReportSummary = data.calculate;
    this.originalRiderConfig = JSON.parse(
      JSON.stringify(this.riderReportConfig)
    );
  }

  @Mutation
  [Mutations.SET_ORIGINAL_RIDER_CONFIG](data) {
    this.originalRiderConfig = data;
  }

  @Action
  [Actions.GET_ORDER_REPORT]({ credentials, configOrder }) {
    const config =
      configOrder || JSON.parse(JSON.stringify(this.orderReportConfig));
    config.startDate = moment(config.startDate).format("YYYY-MM-DD");
    config.endDate = moment(config.endDate).format("YYYY-MM-DD");
    const params = {
      ...credentials,
    };
    const province = window.localStorage.getItem("user_province") || "";
    return (
      ApiService.post(
        `/api/v1/partner/${province
          .toString()
          .replace(/ /g, "")
          .toLowerCase()}/search/order_report_food/order_type/order_status/datetime_start/datetime_end/${
          config.orderType
        }/${config.orderStatus}/${config.startDate}/${config.endDate}`,
        params
      )
        .then(({ data }) => {
          // eslint-disable-next-line prettier/prettier
          if (!configOrder) {
            this.context.commit(Mutations.SET_ORDER_REPORT, data.data);
          }
          return data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }

  @Action
  [Actions.GET_PROMOTION_REPORT]({ credentials, configPromotion }) {
    const config =
      configPromotion || JSON.parse(JSON.stringify(this.promotionReportConfig));
    config.startDate = moment(config.startDate).format("YYYY-MM-DD");
    config.endDate = moment(config.endDate).format("YYYY-MM-DD");
    const params = {
      ...credentials,
    };
    const province = window.localStorage.getItem("user_province") || "";
    return (
      ApiService.post(
        `/api/v1/partner/${province
          .toString()
          .replace(/ /g, "")
          .toLowerCase()}/search/promotion_report/datetime_start/datetime_end/${
          config.startDate
        }/${config.endDate}`,
        params
      )
        .then(({ data }) => {
          // eslint-disable-next-line prettier/prettier
          if (!configPromotion) {
            this.context.commit(Mutations.SET_PROMOTION_REPORT, data.data);
          }
          return data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }

  @Action
  [Actions.GET_RIDER_REPORT](credentials) {
    const params = {
      rider_status: this.riderReportConfig.riderStatus,
      datetime_start: moment(this.riderReportConfig.startDate).format(
        "YYYY-MM-DD"
      ),
      datetime_end: moment(this.riderReportConfig.endDate).format("YYYY-MM-DD"),
      ...credentials,
    };
    return (
      ApiService.query(`/api/v1/partner/report_rider`, {
        params: params,
      })
        .then(({ data }) => {
          // eslint-disable-next-line prettier/prettier
          this.context.commit(Mutations.SET_RIDER_REPORT, data.data);
          return data;
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch(({ response }) => {
          return false;
        })
    );
  }

  @Action
  [Actions.REMOVE_BODY_CLASSNAME](className) {
    document.body.classList.remove(className);
  }

  @Action
  [Actions.ADD_BODY_ATTRIBUTE](payload) {
    const { qulifiedName, value } = payload;
    document.body.setAttribute(qulifiedName, value);
  }

  @Action
  [Actions.REMOVE_BODY_ATTRIBUTE](payload) {
    const { qulifiedName } = payload;
    document.body.removeAttribute(qulifiedName);
  }

  @Action
  [Actions.ADD_CLASSNAME](payload) {
    this.context.commit(Mutations.SET_CLASSNAME_BY_POSITION, payload);
  }
}
